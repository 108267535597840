<template>
  <div class="checkbox" :style="style">
    <FormulateInput
      type="checkbox"
      :value="value"
      :label="label"
      :checked="value"
      :name="name"
      :disabled="disabled"
      @input="checkboxChanged"
    >
      <template #label="{ label, id }">
        <label
          :for="id"
          class="formulate-input-label formulate-input-label--after"
          v-html="label"
        />
      </template>
    </FormulateInput>
  </div>
</template>

<script>
const colors = require("/colors.config.json");
export default {
  name: "Checkbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: `${colors.background_3}`,
    },
    color: {
      type: String,
      default: `${colors.green}`,
    },
    padding: {
      type: String,
      default: "",
    },
    borderRadius: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      let output = {
        "--background-color": this.bgColor,
        "--color": this.color,
      };

      if (this.padding) output.padding = this.padding;
      if (this.borderRadius) output.borderRadius = this.borderRadius;

      return output;
    },
  },
  methods: {
    checkboxChanged(value) {
      this.$emit("changed", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.checkbox {
  width: max-content;
  background-color: var(--background-color);
  padding: 8px;
  border-radius: 4px;
  color: var(--color);

  ::v-deep .formulate-input {
    label {
      margin-bottom: 0;
      font-weight: 400;

      &.formulate-input-element-decorator {
        background-color: $white;
      }
    }
  }
}
</style>
