<template>
    <div class="view content-padding contract-parties">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="contract-parties-controls">
                    <Checkbox
                        :label="`${labels.include} ${labels.admins.toLowerCase()}`"
                        name="admins-included"
                        :value="adminsIncluded"
                        @changed="kindToggle('admins', $event)"
                        :key="updateKey"
                    />
                </div>
                <div :class="{'contract-parties-controls': true, 'contract-parties-dynamic': true, 'contract-parties-disabled': !adminsIncluded }">
                    <Checkbox
                        :label="`${labels.all} ${labels.admins.toLowerCase()}`"
                        name="all-admins"
                        :value="allAdminsSelected"
                        @changed="switchAllParties('admins', $event)"
                        :key="updateKey"
                    />
                </div>
                <div v-if="parties.admins" :class="{'contract-parties-list': true, 'contract-parties-dynamic': true, 'contract-parties-disabled': !adminsIncluded || allAdminsDisabled}">
                    <div v-for="admin in parties.admins" :key="`admin_${admin.id}`" class="contract-parties-list-partie">
                        <span>
                            {{admin.username}}
                        </span>
                        <Button
                            v-show="!allAdminsDisabled"
                            @click="togglePartie('admins', admin.id)"
                            :label="partieAction(admin.selected)"
                            variant="btn-purple"
                            :styles="actionBtnStyle"
                        />
                    </div>
                </div>
                <div v-else class="text-center contract-parties-list">{{labels.no_admins}}</div>
            </div>
            <div class="col-12 col-md-6">
                <div class="contract-parties-controls">
                    <Checkbox
                        :label="`${labels.include} ${labels.accounts.toLowerCase()}`"
                        name="accounts-included"
                        :value="accountsIncluded"
                        @changed="kindToggle('accounts', $event)"
                        :key="updateKey"
                    />
                </div>
                <div :class="{'contract-parties-controls': true, 'contract-parties-dynamic': true, 'contract-parties-disabled': !accountsIncluded }">
                    <Checkbox
                        :label="`${labels.all} ${labels.accounts.toLowerCase()}`"
                        name="all-accounts"
                        :value="allAccountsSelected"
                        @changed="switchAllParties('accounts', $event)"
                        :key="updateKey"
                    />
                </div>
                <div v-if="parties.accounts" :class="{'contract-parties-list': true, 'contract-parties-dynamic': true, 'contract-parties-disabled': !accountsIncluded || allAccountsDisabled}">
                    <div v-for="account in parties.accounts" :key="`account_${account.id}`" class="contract-parties-list-partie">
                        <span>
                            {{account.username}}
                        </span>
                        <Button
                            v-show="!allAccountsDisabled"
                            @click="togglePartie('accounts', account.id)"
                            :label="partieAction(account.selected)"
                            variant="btn-purple"
                            :styles="actionBtnStyle"
                        />
                    </div>
                </div>
                <div v-else class="text-center contract-parties-list">{{labels.no_users}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Button from '@/components/Buttons/Button';
import Checkbox from '@/components/Input/Checkbox';

export default {
    name: "ContractualParties",
    components: {
        Button,
        Checkbox
    },
    data() {
        return {
            updateKey: Date.now(),
            adminsIncluded: false,
            accountsIncluded: false,
            allAdminsSelected: false,
            allAccountsSelected: false,
            allAdminsDisabled: false,
            allAccountsDisabled: false,
            parties: {
                admins: [],
                accounts: []
            },
            recipients: [],
            actionBtnStyle: {
                padding: '8px 15px',
                fontWeight: '700',
                selfAlign: 'left'
            }
        }
    },
    methods: {
        ...mapMutations( 'helper', {
			setTablePagination: 'setTablePagination',
		} ),
        ...mapActions('notification', {
			addNotification: 'addNotification',
		}),
        ...mapActions( 'user', {
			getUsers: 'getUsers',
		} ),
        ...mapActions("message", {
            getSingle: "getSingle",
            updateMessage: "update",
        }),
        ...mapGetters("message", {
            singleData: "singleData",
        }),
        generateRecipients() {
            this.recipients = [];

            for (let key in this.parties) {
                if (key == 'admins') {
                    if (!this.allAdminsSelected) {
                        this.parties[key].forEach((recipient) => {
                            if (recipient.selected) this.recipients.push(recipient.id);
                        });
                    }
                } else if (key == 'accounts') {
                    if (!this.allAccountsSelected) {
                        this.parties[key].forEach((recipient) => {
                            if (recipient.selected) this.recipients.push(recipient.id);
                        });
                    }
                }
            }
        },
        saveData() {
            this.generateRecipients();

            const finalData = {
                id: this.singleData()._id,
                payload: {
                    recipients: this.recipients,
                    adminRecipient: this.adminsIncluded,
                    accountRecipient: this.accountsIncluded,
                    allAdmins: this.allAdminsSelected,
                    allAccounts: this.allAccountsSelected,
                }
            }

            this.updateMessage(finalData).then( res => {
				if (res.success) {
                    this.addNotification( { variant: 'success', msg: ['saved'], labels: this.labels, dismissSecs: 5 })
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
			})
			.catch( err => console.log( err ) );
        },
        createParties(users, recipients) {
            users.forEach(user => {
                const newUser = {
                    id: user._id,
                    username: user.username,
                    selected: false
                }

                if(recipients.includes(newUser.id)) newUser.selected = true;

                if(user.role.kind === 'Admin') {
                    this.parties.admins.push(newUser)
                } else {
                    this.parties.accounts.push(newUser)
                }
                
                // this.checkKindAllSelected('admins')
                // this.checkKindAllSelected('accounts')
            })
        },
        getSingleData(users) {
			this.getSingle( { id: this.$route.params.id } )
			.then( res => {
				if (res.success) {
                    if(res.data.accountRecipient) {
                        this.accountsIncluded = res.data.accountRecipient;
                    }
                    if(res.data.adminRecipient) {
                        this.adminsIncluded = res.data.adminRecipient;
                    }
                    this.updateKey = Date.now();
                    this.createParties(users, res.data.recipients)
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
			})
			.catch( err => console.log( err ) );
		},
        getUserList( page ) {
			let data = { page }
			return this.getUsers( data )
				.then( res => {
					if ( res.success ) {
                        this.getSingleData(res.data.data)
						if ( Number(this.$route.query.page) !== Number(res.data.pages.current) ) {
							this.$router.push( { query: { page: res.data.pages.current } } );
						}
					} else if ( res.error ) {
						this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels } );
					}
					return res;
				} )
				.catch( err => console.log( err ) );
		},
        togglePartie(kind, id) {
            const partieIndex = this.getIndexOfObject(this.parties[kind], 'id', id);

            if (partieIndex > -1) {
                this.parties[kind][partieIndex].selected = !this.parties[kind][partieIndex].selected;

                this.checkKindAllSelected(kind);
            }
        },
        getIndexOfObject(array, attr, value) {
            for(var i = 0; i < array.length; i += 1) {
                if(array[i][attr] === value) {
                    return i;
                }
            }

            return -1;
        },
        partieAction(isSelected) {
            if (isSelected) return this.labels.deselect;
            return this.labels.select;
        },
        switchAllParties(kind, value) {
            if(kind == 'admins'){
                if(value) {
                this.allAdminsDisabled = true
                this.allAdminsSelected = true
                }
                else {
                this.allAdminsDisabled = false
                this.allAdminsSelected = false
                }
            }
            if(kind == 'accounts'){
                if(value) {
                this.allAccountsSelected = true
                this.allAccountsDisabled = true
                }
                else {
                this.allAccountsDisabled = false
                this.allAccountsSelected = false
                }
            }

            this.parties[kind].forEach((partie) => {
                partie.selected = value;
            });
        },
        kindToggle(kind, value) {
            switch (kind) {
                case 'admins':
                    this.adminsIncluded = value;

                    if (!value) {
                        this.allAdminsSelected = false;
                        this.updateKey = Date.now();
                    }
                    break;
                case 'accounts':
                    this.accountsIncluded = value;

                    if (!value) this.allAccountsSelected = false;
                    break;
            }
        },
        checkKindAllSelected(kind) {
            let all = true;
            
            if (this.parties[kind].length) {
                this.parties[kind].forEach( (partie) => {
                    if (!partie.selected) {
                        all = false;
                        return;
                    }
                } );

                
                switch (kind) {
                case "admins":
                    this.allAdminsSelected = all;
                    this.allAdminsDisabled = all;
                    break;
                case "accounts":
                    this.allAccountsSelected = all;
                    this.allAccountsDisabled = all;
                    break;
                }

                this.updateKey = Date.now();
            }
        }
    },
    computed:{
        ...mapGetters('helper', ['labels']),
    },
    watch: {
        adminsIncluded(state) {
            if (!state) {
                this.parties.admins.forEach( (admin) => {
                    admin.selected = state;
                } );
            }
        },
        accountsIncluded(state) {
            if (!state) {
                this.parties.accounts.forEach( (account) => {
                    account.selected = state;
                } );
            }
        },
    },
    mounted() {
        // this.checkKindAllSelected('admins');
        // this.checkKindAllSelected('accounts');
        this.getUserList(1);

        this.getSingle({ id: this.$route.params.id }).then(res=>{
            console.log(res)
            this.allAccountsSelected = res.data.allAccountRecipient
            this.allAdminsSelected = res.data.allAdminRecipient

            
            this.allAccountsDisabled = res.data.allAccountRecipient
            this.allAdminsDisabled = res.data.allAdminRecipient
        })
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/variables';
    .contract-parties {
        &-dynamic {
            transition: all 300ms ease-out;
        }

        &-disabled {
            opacity: 0.3;
            pointer-events: none;
        }
        
        &-controls {
            margin-bottom: $column_top_margin;

            ::v-deep {
                div.formulate-input-wrapper {
                    width: 100%;
                    flex-direction: row-reverse;

                    label {
                        margin-left: 0px;
                        margin-right: 15px;
                        flex: 1 0 auto;
                    }
                }
                
                div.checkbox {
                    width: 100%;
                }
            }
        }

        &-list {
            border: 2px solid $background;
            padding: $column_top_margin;

            &-partie {
                width: 100%;
                margin-top: $column_top_margin;
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                    flex: 1 0 auto;
                    margin-right: 15px;
                    word-break: break-word;
                }
            }

            &-partie:first-child {
                margin-top: 0px;
            }
        }
    }
    
    ::v-deep {
        .table-card {
            &__thead {
                font-family: "Oswald", sans-serif;
                text-transform: uppercase;
                font-weight: 400;
                line-height: 55px;
                color: $light_text;
            }

            &-row {
                &__item {
                    word-break: break-word;
                }

                &__btn {
                    justify-content: flex-start;
                }
            }
        }
    }
</style>